import { Box, Typography } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useMutation, useQuery } from "@apollo/client";
import {
  Module,
  Treatment,
  UseSelectTranslationStringInputFragment,
} from "../../../api/__generated__/graphql";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import Header from "./Common/Header";
import StepMainContent from "./Common/StepMainContent";
import StepHeader from "./Common/StepHeader";
import Footer from "./Common/Footer";
import { useTranslation } from "react-i18next";
import BloomieCard from "./Common/BloomieCard";
import Button from "../../ui/buttons/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import environment from "../../../environment/environment";

const treatmentsQuery = graphql(`
  query Treatments {
    treatments {
      availableTreatments
      module {
        id
        title {
          ...useSelectTranslationStringInput
        }
        url
      }
      proposedTreatment
    }
  }
`);

const submitTreatmentStepMutation = graphql(`
  mutation SubmitTreatmentStep($treatment: Treatment!) {
    submitTreatmentStep(treatment: $treatment) {
      matchingToken
      nextStep
    }
  }
`);

interface TreatmentStepProps {
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}

interface TreatmentCard {
  buttonOnClick: () => void;
  buttonText?: {
    __typename?: "MultiLanguageString";
  } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
  buttonTextKey: string;
  descKey: string;
  id: string;
  imgUrl: string;
  proposed: boolean;
  titleKey: string;
}

const formatTreatments = (
  treatments: Treatment[],
  proposedTreatment: Treatment | null | undefined,
  submit: (treatment: Treatment) => void,
  module?: Module,
) => {
  const coachingCard: TreatmentCard = {
    buttonOnClick: () => {
      submit("COACHING");
    },
    buttonTextKey: "match:treatmentStep.treatments.coaching.button",
    descKey: "match:treatmentStep.treatments.coaching.desc",
    id: "COACHING",
    imgUrl:
      "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D75-coaching%2C+Size%3DSmall.png",
    proposed: proposedTreatment === "COACHING",
    titleKey: "match:treatmentStep.treatments.coaching.title",
  };

  const therapyCard: TreatmentCard = {
    buttonOnClick: () => {
      submit("THERAPY");
    },
    buttonTextKey: "match:treatmentStep.treatments.coaching.button",
    descKey: "match:treatmentStep.treatments.therapy.desc",
    id: "THERAPY",
    imgUrl:
      "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D77-therapy%2C+Size%3DSmall.png",
    proposed: proposedTreatment === "THERAPY",
    titleKey: "match:treatmentStep.treatments.therapy.title",
  };

  const selfHelpCard: TreatmentCard = {
    buttonOnClick: () => {
      submit("SELFHELP");
      window.location.href = module
        ? module.url
        : `${environment.MINDLAB_FRONTEND_URL}/modules`;
    },
    buttonText: module?.title,
    buttonTextKey: "match:treatmentStep.treatments.selfHelp.button",
    descKey: "match:treatmentStep.treatments.selfHelp.desc",
    id: "SELFHELP",
    imgUrl:
      "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D76-selfhelp%2C+Size%3DSmall.png",
    proposed: proposedTreatment === "SELFHELP",
    titleKey: "match:treatmentStep.treatments.selfHelp.title",
  };

  const treatmentCards: TreatmentCard[] = [];

  treatments.forEach((treatment) => {
    switch (treatment) {
      case "COACHING":
        treatmentCards.push(coachingCard);
        break;

      case "THERAPY":
        treatmentCards.push(therapyCard);
        break;

      case "SELFHELP":
        treatmentCards.push(selfHelpCard);
        break;
    }
  });

  if (treatmentCards.length !== 3 || !proposedTreatment) {
    return treatmentCards;
  }

  const sortedTreatments = treatmentCards.filter(
    (treatment) => treatment.id !== proposedTreatment,
  );

  sortedTreatments.splice(
    1,
    0,
    treatmentCards.filter((treatment) => treatment.id === proposedTreatment)[0],
  );

  return sortedTreatments;
};

const TreatmentStep = ({ setNextStep, stepBack }: TreatmentStepProps) => {
  const { data } = useQuery(treatmentsQuery, {
    fetchPolicy: "network-only",
  });

  const [submitTreatmentStep] = useMutation(submitTreatmentStepMutation);

  const { t } = useTranslation();
  const submit = async (treatment: Treatment) => {
    const { data: submitData } = await submitTreatmentStep({
      variables: {
        treatment,
      },
    });

    submitData?.submitTreatmentStep.nextStep &&
      setNextStep(submitData.submitTreatmentStep.nextStep);

    submitData?.submitTreatmentStep.matchingToken &&
      localStorage.setItem(
        "matchingToken",
        submitData.submitTreatmentStep.matchingToken,
      );
  };

  const formattedTreatments = data
    ? formatTreatments(
        data.treatments.availableTreatments,
        data.treatments.proposedTreatment,
        submit,
        data.treatments.module !== null ? data.treatments.module : undefined,
      )
    : [];

  const selectTranslation = useSelectTranslation();

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Header />
      <StepHeader
        imageUrl={
          "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D66-colleague%2C+Size%3DSmall.png"
        }
        subtitle={t("match:treatmentStep.subTitle")}
        title={t("match:treatmentStep.title")}
      />
      <StepMainContent
        enableNext={false}
        onClickNext={function (): void {
          throw new Error("Function not implemented.");
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            display: "flex",
            gap: "50px",
            padding: "45px 100px 52px 100px",
          }}
        >
          {formattedTreatments.map((treatment) => {
            return (
              <BloomieCard
                bodyText={t(treatment.descKey)}
                footerChildren={
                  <Box
                    sx={{
                      width: treatment.proposed ? "110%" : undefined,
                    }}
                  >
                    <Button
                      bloomColor={treatment.proposed ? "green" : undefined}
                      endIcon={
                        treatment.proposed ? <ArrowForwardIcon /> : undefined
                      }
                      fullyColored={treatment.proposed}
                      label={
                        treatment.buttonText
                          ? selectTranslation(treatment.buttonText)
                          : t(treatment.buttonTextKey)
                      }
                      onClick={treatment.buttonOnClick}
                      size={treatment.proposed ? "large" : "medium"}
                    />
                    {treatment.id === "SELFHELP" && (
                      <Link
                        style={{
                          alignItems: "center",
                          display: "flex",
                          gap: 6,
                          justifyContent: "center",
                          marginTop: "0.5rem",
                          textDecoration: "none",
                        }}
                        target="blank"
                        to={`${environment.MINDLAB_FRONTEND_URL}/modules`}
                      >
                        <Typography>
                          {t("match:treatmentStep.moduleLink")}
                        </Typography>
                        <ArrowForwardIcon />
                      </Link>
                    )}
                  </Box>
                }
                imgUrl={treatment.imgUrl}
                size={treatment.proposed ? "xl" : "lg"}
                subTitle={
                  treatment.proposed
                    ? t("match:treatmentStep.treatments.ourAdvice")
                    : t("match:treatmentStep.treatments.alternative")
                }
                title={t(treatment.titleKey)}
                type={"outlined"}
              />
            );
          })}
        </Box>
      </StepMainContent>
      <Footer />
    </Box>
  );
};

export default TreatmentStep;
