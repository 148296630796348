import { Box, Typography } from "@mui/material";

const RegisterStep = () => {
  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> REGISTER </Typography>
    </Box>
  );
};

export default RegisterStep;
